.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.edit, .delete {
  cursor: pointer;
  margin-top: 3px;
}

.edit:hover {
  text-decoration: underline;
}
.fechaCreado{
  font-family: ItalianPlateNoRegular;
  text-align: right;
}
.inputPhone{
  height: 40.14px;
  padding: 10px 10px;
}
.correlativo{
  font-family: ItalianPlateNoRegular;
  text-align: left;
  margin-bottom: 5px;
}
.sucursal{
  font-family: ItalianPlateNoRegular;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 5px;
}
.descripcion{
  text-transform:uppercase;
  font-family: ItalianPlateNoRegular;
  text-align: left;
  margin-top: 20px;
  color: "#545454",
}
.txAdjunto{
  color: "#97B0C1";
  font-family: ItalianPlateNoBold;
  font-size: 16px;
  margin-top: 25px;
  text-transform:uppercase;
}
.contImages{
  flex-direction: row;
  margin-top: 10px;
}
.contImages img{
  cursor: pointer;
}
.linea{
  background-color: #6F7B81;
  height: 1px;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 10px;
}

.actions {
  display: flex;
  align-items: left;
  justify-content: left;
}
.txDetalleTarea {
  font-family: ItalianPlateNoBold;
  color:"#6F7B81";
  font-size: 14px;
}
.divider {
  width: 1px;
  background-color: #C9C9C9;
  height: 22px;
  margin: 0 15px;
}
