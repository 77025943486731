@import '~antd/dist/antd.less';

@font-face {
  font-family: Bree;
  src: url(assets/fonts/bree/BreeLight.otf);
  font-weight: 300;
}

@font-face {
  font-family: Bree;
  src: url(assets/fonts/bree/BreeRegular.otf);
  font-weight: 400;
}

@font-face {
  font-family: Bree;
  src: url(assets/fonts/bree/BreeBold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Bree;
  src: url(assets/fonts/bree/BreeExtrabold.otf);
  font-weight: 800;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url(assets/fonts/roboto/Roboto-Black.ttf);
  font-weight: 800;
}

@font-face {
  font-family: ItalianPlateNoBold;
  src: url(assets/fonts/italian/ItalianPlateNoBold.otf);
}
@font-face {
  font-family: ItalianPlateNoRegular;
  src: url(assets/fonts/italian/ItalianPlateNoRegular.otf);
}

body {
  margin: 0;
  font-family: Roboto, 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --green: #17233C;
  --black: #3E3E3E;
  --gray: #808080;
  --darkGray: #34586B;
  --offRed: #FEF2F4;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  color: #A1A1A1;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  width: 100%;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--darkGray);
  font-weight: 700;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: var(--green);
  pointer-events: none;
}

.ant-tabs-ink-bar {
  height: 4px !important;
}

.ant-tabs-nav::before {
  border-bottom: 1px solid #A4A4A4 !important;
}

/* .ant-switch-checked {
  background-color: var(--green);
} */

.ant-layout-sider-zero-width-trigger {
  background-color: var(--darkGray);
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
}

.ant-tabs-tab {
  flex-grow: 1;
  margin-right: 0px;
  width: 100%;
  text-align: center;
}

.ant-select-selector {
  border-radius: 6px !important;
}

.ant-form-item-control {
  width: 100%;
}

.editor-product .ant-select-selector {
  background-color: #FBFBFB !important;
  border-color: #D2D2D2 !important;
}

.editor-product .ant-upload.ant-upload-select {
  width: 100%;
}

.ant-checkbox input {
  border-color: #BFBFBF;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}

.main-button {
  width: 300px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 14px;
}
.main-buttonHeader {
  width: 200px;
  font-weight: 500;
  border-radius: 6px;
  font-size: 14px;
}
.ant-pagination-item-active a{
  color: var(--green);
}
.ant-pagination-item-active {
  border-color: var(--green);
}

.ant-upload-picture-card-wrapper {
  display: flex !important;
  width: auto !important;
  padding-bottom: 20px;
}

.ant-upload-picture-card-wrapper img {
  width: 102px;
  height: 102px;
}

.imagesBlock{
  display: flex;
}
@primary-color: #35CA9C;@border-radius-base: 4px;